import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';

import Layout from 'components/Order/Layout';
import Auth from 'components/Auth';
import Assistance from 'components/Order/Assistance';
import CcAuth from 'components/CcAuth';
import TitleName from 'components/UI/Header/TitleName';
import ModalDiscount from 'components/Cart/ModalDiscount';

import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { setPurchase } from 'utils/_gtag.js';
import {
  PATH_CART,
  PATH_REFUND_CANCEL,
  PATH_PRODUCT,
} from 'utils/constants/paths.js';

import IconOrderStatus1 from 'images/order-status-1.svg';
import IconOrderStatus2 from 'images/order-status-2.svg';
import IconOrderStatus3 from 'images/order-status-3.svg';
import IconArrowUp from 'images/arrow-up.svg';
import IconArrowDown from 'images/arrow-down.svg';
import IconDiscountNotice from 'images/discount-notice.svg';
import IconTooltipClose from 'images/close-2.svg';

import Cookies from 'js-cookie';

const Container = styled.div`
  @media (max-width: ${md}) {
    padding-bottom: 248px;
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    z-index: 1000;
    padding: 24px 15px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    flex: 1;
    gap: 10px 0;
    flex-direction: column;
  }
`;

const TitleBarBtn = styled.div`
  width: 114px;
  height: 38px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 30px;

  @media (max-width: ${md}) {
    width: 100%;
    height: 60px;
    background-color: #5fd2da;
    border: 1px solid #5fd2da;
    border-radius: 40px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
      border-radius: 30px;
    `}
`;

const Card = styled.div`
  width: 100%;
  min-height: 717px;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;

  @media (max-width: ${md}) {
    min-height: auto;
  }
`;

const CardTitleBar = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;

  @media (max-width: ${md}) {
    flex: 1 0 calc(100% - 36px - 20px);
    flex-wrap: wrap;
    gap: 8px 0;
    border-bottom: none;
  }

  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
  .skeleton-card-bar {
    width: 100%;
    .skeleton-title {
      min-height: 88px;
      @media (max-width: ${md}) {
        min-height: 120px;
      }
    }
  }
`;

const IconStatus = styled.img`
  margin-right: 16px;
`;

const CardTitleBarBlock = styled.div``;

const OrderStatus = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #3b3516;
`;

const EstimatedTime = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #3b3516;
`;

const OrderTracking = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: blue;
  cursor: pointer;
`;

const OrderNumber = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-left: auto;

  @media (max-width: ${md}) {
    margin-left: 0;
  }
`;

const CardContentTemplate1 = styled.div`
  padding: 12px 16px;

  @media (max-width: ${md}) {
    padding: 12px 16px 0 16px;
  }
  .skeleton-product-list {
    min-height: 400px;
  }
  .skeleton-delivery-title {
    min-height: 130px;
  }
`;

const CardContentTemplate2 = styled.div`
  padding: 0 16px;
`;

const CardContentRow = styled.div`
  display: flex;
  justify-content: space-between;

  &.total {
    @media (max-width: ${md}) {
      background-color: #f2f2f2;
      padding: 24px 16px;
      margin: 0 -16px;
    }
  }
`;

const CardContentRowLabel = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #333333;

  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

const CardContentRowValue = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #333333;
`;

const DeliveryBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0f0f0;
`;

const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  padding-top: 12px;
`;

const ProductListWrapper = styled.div``;

const ExpandBtnWrapper = styled.div`
  padding: 20px 0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;

  @media (max-width: ${md}) {
    padding: 16px 0;
  }
`;

const ExpandBtn = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  position: relative;

  &::before {
    content: '展開更多';
  }

  &::after {
    content: '';
    background-image: url(${IconArrowDown});
    background-size: cover;
    width: 11px;
    height: 6px;
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
  }

  ${(props) =>
    props.isExpand &&
    css`
      &::before {
        content: '收起';
      }

      &::after {
        content: '';
        background-image: url(${IconArrowUp});
      }
    `}
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  margin: 12px 0;
  max-height: ${(props) => (props.isExpand ? 'auto' : '120px')};
  overflow: hidden;

  @media (max-width: ${md}) {
    margin: 16px 0;
    max-height: ${(props) => (props.isExpand ? 'auto' : '100px')};
  }

  ${(props) =>
    props.isExpand &&
    css`
      margin-bottom: 12px;
    `}
`;

const Product = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;

  @media (max-width: ${md}) {
    align-items: flex-start;
  }
`;

const ProductCount = styled.div`
  flex: 0 0 28px;
  height: 28px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;
const DeleteText = styled.div`
  width: 74px;
  height: 28px;
  background-color: #bef0f3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProductName = styled.div`
  @media (max-width: ${md}) {
    flex: 0 0 230px;
  }
`;

const ProductPrice = styled.div`
  margin-left: auto;
  flex: 0 0 auto;
`;

const PromotionLabelList = styled.div``;

const PromotionLabel = styled.div`
  display: inline-block;
  background-color: #fff4bc;
  border-radius: 30px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 400;
  color: #3b3516;
`;

const OrderInfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0 32px;
  padding: 12px 0;

  @media (max-width: ${md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 7px 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;

    @media (max-width: ${md}) {
      border-bottom: none;
    }
  }
`;

const OrderInfoBlockLabel = styled.div`
  flex-basis: 90px;
  font-size: 18px;
  font-weight: 700;
  color: #8185ca;

  @media (max-width: ${md}) {
    flex-basis: auto;
  }
`;

const OrderInfoBlockValue = styled.div`
  flex-basis: 340px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  display: flex;
  flex-direction: column;
  gap: 15px 0;

  @media (max-width: ${md}) {
    flex-basis: auto;
  }
`;

const AssistanceWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const QuestionnaireWrapper = styled.div`
  background-color: #d7f4f699;
  padding: 16px;
`;

const QuestionnaireBtn = styled.a`
  display: inline-block;
  width: auto;
  text-align: center;
  background-color: #5fd2da;
  border-radius: 20px;
  color: #fff;
  margin-top: 12px;
  padding: 4px 12px;
  text-decoration: none;
  box-sizing: border-box;
`;

const QuestionnaireTitle = styled.div``;

const DiscountNoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  position: relative;

  > img {
    cursor: pointer;
  }
`;
const Tooltip = styled.div`
  width: 340px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 15px);
  left: -50px;
  z-index: 100;
  padding: 12px 16px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    box-shadow: -2px -2px 0.5px rgba(128, 128, 128, 0.1);
    background-color: #fff;
    position: absolute;
    top: -8px;
    left: 53px;
    transform: rotate(45deg);
  }
`;
const TooltipCloseBtn = styled.div`
  background-image: url(${IconTooltipClose});
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12px;
  right: 16px;
  cursor: pointer;
`;
const DiscountList = styled.div`
  width: 90%;
`;
const DiscountWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
`;
const Discount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 0 10px;
  padding: 8px 0;
`;
const DiscountDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;
const DiscountPrice = styled.div``;

const DiscountNameBlock = styled.div`
  margin-bottom: 10px;
  margin-left: 15px;
`;

const DiscountName = styled.div``;

const OrderType = {
  FINISHED: '30', //完成
  CANCEL: '40', // 取消
  DISPATCH: '20', //配送中
  TEN: '10', // 狀態10
};

const OrderDetail = (props) => {
  const pageTitle = '訂單詳情';
  const { transNo } = props;
  const api = useAPI();
  const loading = useLoading();

  const [cardContentTemplate, setCardContentTemplate] = useState(1);
  const [orderStatusId, setOrderStatusId] = useState();
  const [orderDetail, setOrderDetail] = useState({});
  const [isExpandProductList, setIsExpandProductList] = useState(false);
  const [showAssistance, setShowAssistance] = useState(false);
  const [amount, setAmount] = useState(0);
  const [deliveryTime, setDeliveryTime] = useState('');
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const [showDiscountTooltip, setShowDiscountTooltip] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [promotionInfo, setPromotionInfo] = useState({});

  const from = useRef('');

  //倒數計時顯示：estimated_delivery_time時間到都沒更新頁面，預計抵達時間必須顯示"--"
  const timeCountDown = (timestamp) => {
    if (timestamp !== 0) {
      const date = new Date(timestamp * 1000);
      const nowTime = new Date();
      const timeDifference = date.getTime() - nowTime.getTime();

      const delayedSetDeliveryTime = _.debounce(() => {
        setDeliveryTime('--');
      }, timeDifference);

      delayedSetDeliveryTime();
    }
  };

  const expandProductList = () => {
    setIsExpandProductList((state) => !state);
  };

  const getOrderDetail = (transNo, source) => {
    loading.show();
    api
      .getOrderDetail(transNo, source)
      .then((res) => {
        setOrderDetail(res);
        setOrderStatusId(res.ecorder_status_id);
        setDeliveryTime(res.delivery_datetime);
        setPromotionInfo(res.promotionInfo);

        timeCountDown(res.estimated_delivery_time);

        var total = 0;
        var discount = 0;
        for (const i of res.products) {
          total += i.subtotal;
        }
        for (const i of res.refund_products) {
          discount += i.subtotal;
        }

        setAmount(total - discount);

        // GTAG: 1. 完成購買
        if (from.current === 'result') {
          const attributionToken = Cookies.get('attributionToken');
          setPurchase(res, attributionToken);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => loading.dismiss());
  };

  const reOrder = () => {
    loading.show();
    api
      .reOrder(transNo)
      .then((res) => {
        if (!res) return;
        navigate(PATH_CART, { replace: true });
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => loading.dismiss());
  };

  const closeAssistance = () => {
    setShowAssistance(false);
  };

  const handleClickAssistance = () => {
    if (isMobile) {
      navigate(`/assistance/${transNo}`, {
        state: { orderDetail },
      });
    } else {
      setShowAssistance(true);
    }
  };

  const handleClickCancel = () => {
    navigate(PATH_REFUND_CANCEL, {
      state: { orderNo: orderDetail.order_no },
    });
  };

  const handleClickProduct = (item) => {
    navigate(`${PATH_PRODUCT}?pid=${item.productnumber}`);
  };

  const handleClickDiscountIcon = () => {
    isMobile ? setShowDiscountModal(true) : setShowDiscountTooltip(true);
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get('source');

    let currentUrl = typeof window !== 'undefined' ? window.location.href : '';
    // 用問號將URL分為基本路徑和查詢字串
    const [basePath, queryString] = currentUrl.split('?');
    // 解析查詢字串
    const query = {};
    if (queryString) {
      queryString.split('&').forEach((item) => {
        const [key, value] = item.split('=');
        query[key] = decodeURIComponent(value);
      });
    }
    from.current = query['from'];
    console.log('GTAG: from = ', from.current);
    getOrderDetail(transNo, source);
  }, [ccAuthIsReady]);

  // console.log('orderDetail.products', orderDetail.products);

  return (
    <>
      <TitleName />
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <Layout {...props} pageTitle={pageTitle}>
          <Auth />
          <Container>
            <TitleBar>
              <Title>{pageTitle}</Title>
              <BtnGroup>
                {!isMobile && (
                  <TitleBarBtn
                    active={cardContentTemplate === 1}
                    onClick={() => setCardContentTemplate(1)}
                  >
                    訂單資訊
                  </TitleBarBtn>
                )}
                <TitleBarBtn
                  active={showAssistance}
                  onClick={handleClickAssistance}
                >
                  取得協助
                </TitleBarBtn>
                <TitleBarBtn
                  active={cardContentTemplate === 2}
                  onClick={() => setCardContentTemplate(2)}
                >
                  其他資訊
                </TitleBarBtn>
                {orderDetail.can_cancel && (
                  <TitleBarBtn onClick={handleClickCancel}>
                    訂單取消
                  </TitleBarBtn>
                )}
                {[OrderType.CANCEL, OrderType.FINISHED].includes(
                  orderStatusId
                ) && <TitleBarBtn onClick={reOrder}>重新下單</TitleBarBtn>}
              </BtnGroup>
            </TitleBar>
            <Card>
              <CardTitleBar
                hide={isMobile && [2].includes(cardContentTemplate)}
              >
                {Object.keys(orderDetail).length > 0 ? (
                  <>
                    <IconStatus
                      src={
                        [OrderType.TEN, OrderType.DISPATCH].includes(
                          orderStatusId
                        )
                          ? IconOrderStatus1
                          : [OrderType.FINISHED].includes(orderStatusId)
                          ? IconOrderStatus2
                          : IconOrderStatus3
                      }
                    />
                    <CardTitleBarBlock>
                      <OrderStatus>{orderDetail.ship_status}</OrderStatus>
                      {orderDetail.shipping_date !== '' &&
                        orderDetail.expected_shipping_time !== '' && (
                          <EstimatedTime>
                            {orderDetail.ecorder_status_id === OrderType.CANCEL
                              ? '取消：'
                              : orderDetail.ecorder_status_id ===
                                OrderType.FINISHED
                              ? '完成：'
                              : '預計：'}
                            {deliveryTime}
                          </EstimatedTime>
                        )}
                      {orderDetail.order_tracking_url !== '' && (
                        <OrderTracking
                          onClick={() => {
                            confirm(
                              `即將幫您導向物流配合廠商${orderDetail.logistics_name}網頁`
                            ).then(() => {
                              window.open(
                                orderDetail.order_tracking_url,
                                '_blank'
                              );
                            });
                          }}
                        >
                          即時配送狀態
                        </OrderTracking>
                      )}
                    </CardTitleBarBlock>
                    <OrderNumber>訂單號：{orderDetail.order_no}</OrderNumber>
                  </>
                ) : (
                  <Skeleton
                    containerClassName="skeleton-card-bar "
                    className="skeleton-title"
                  />
                )}
              </CardTitleBar>
              {orderDetail.survey_url && (
                <QuestionnaireWrapper>
                  <QuestionnaireTitle>
                    為了讓家樂福做得更好，請花三分鐘，請告訴我們您在家樂福線上購物的購物體驗。
                  </QuestionnaireTitle>
                  <QuestionnaireBtn
                    href={orderDetail.survey_url}
                    target="_blank"
                  >
                    開始做答
                  </QuestionnaireBtn>
                </QuestionnaireWrapper>
              )}

              {[1].includes(cardContentTemplate) && (
                <CardContentTemplate1>
                  <DeliveryBlock>
                    {Object.keys(orderDetail).length > 0 ? (
                      <>
                        <CardContentRow>
                          <CardContentRowLabel>
                            {orderDetail.shipping_model_id === '1'
                              ? '配送店'
                              : '取貨店'}
                          </CardContentRowLabel>
                          <CardContentRowValue>
                            {orderDetail.from_store_name}{' '}
                            {orderDetail.shipping_model_id === '1'
                              ? '外送'
                              : '到店取貨'}
                          </CardContentRowValue>
                        </CardContentRow>
                        <CardContentRow>
                          <CardContentRowLabel>
                            {orderDetail.shipping_model_id === '1'
                              ? '送貨地址'
                              : '取貨地址'}
                          </CardContentRowLabel>
                          <CardContentRowValue>
                            {orderDetail.shipping_model_id === '1'
                              ? `${orderDetail.zipcode ?? ''}${
                                  orderDetail.shipping_address ?? ''
                                }`
                              : orderDetail.to_store_address}
                          </CardContentRowValue>
                        </CardContentRow>
                      </>
                    ) : (
                      <Skeleton className="skeleton-delivery-title" />
                    )}
                  </DeliveryBlock>

                  {Object.keys(orderDetail).length > 0 ? (
                    <>
                      <ProductListWrapper>
                        <ProductList isExpand={isExpandProductList}>
                          {orderDetail.products?.map((item) => {
                            return (
                              <Product onClick={() => handleClickProduct(item)}>
                                <ProductCount>{item.qty}</ProductCount>
                                <PromotionLabelList>
                                  <ProductName>
                                    {`${item.item_name}`}
                                    {item.sold_by_weight !== true &&
                                      ` / ${item.unit_quantity}`}
                                  </ProductName>
                                  {item.calloutmsg !== '' && (
                                    <PromotionLabel>
                                      {item.calloutmsg}
                                    </PromotionLabel>
                                  )}
                                </PromotionLabelList>
                                <ProductPrice>${item.subtotal}</ProductPrice>
                              </Product>
                            );
                          })}
                          {orderDetail.refund_products?.length > 0 && (
                            <DeleteText>刪除商品</DeleteText>
                          )}
                          {orderDetail.refund_products?.map((item) => {
                            return (
                              <Product onClick={() => handleClickProduct(item)}>
                                <ProductCount>{item.qty}</ProductCount>
                                <PromotionLabelList>
                                  <ProductName>
                                    {`${item.item_name}`}
                                    {item.sold_by_weight !== true &&
                                      ` / ${item.unit_quantity}`}
                                  </ProductName>
                                  {item.calloutmsg !== '' && (
                                    <PromotionLabel>
                                      {item.calloutmsg}
                                    </PromotionLabel>
                                  )}
                                </PromotionLabelList>
                                <ProductPrice>-${item.subtotal}</ProductPrice>
                              </Product>
                            );
                          })}
                        </ProductList>
                        {orderDetail.products?.length +
                          orderDetail.refund_products?.length >=
                          3 && (
                          <ExpandBtnWrapper>
                            <ExpandBtn
                              isExpand={isExpandProductList}
                              onClick={expandProductList}
                            />
                          </ExpandBtnWrapper>
                        )}
                      </ProductListWrapper>

                      <PriceBlock>
                        <CardContentRow>
                          <CardContentRowLabel>小計</CardContentRowLabel>
                          <CardContentRowValue>${amount}</CardContentRowValue>
                        </CardContentRow>
                        {orderDetail.shipping_model_id === '1' && (
                          <CardContentRow>
                            <CardContentRowLabel>
                              外送服務費
                            </CardContentRowLabel>
                            <CardContentRowValue>
                              ${orderDetail.shipping_fee}
                            </CardContentRowValue>
                          </CardContentRow>
                        )}
                        {orderDetail.promotion_discount_amount !== 0 && (
                          <CardContentRow>
                            <CardContentRowLabel>
                              折扣
                              <DiscountNoticeWrapper>
                                <img
                                  src={IconDiscountNotice}
                                  onClick={handleClickDiscountIcon}
                                />
                                {showDiscountTooltip && (
                                  <Tooltip>
                                    <TooltipCloseBtn
                                      onClick={() =>
                                        setShowDiscountTooltip(false)
                                      }
                                    />
                                    <DiscountList>
                                      {promotionInfo.orderPromotion && (
                                        <DiscountWrapper>
                                          <Discount>
                                            <DiscountDescription>
                                              {
                                                promotionInfo.orderPromotion
                                                  ?.blockDoc
                                              }
                                            </DiscountDescription>
                                            <DiscountPrice>{`$${promotionInfo.orderPromotion?.discountAmount}`}</DiscountPrice>
                                          </Discount>
                                          <DiscountNameBlock>
                                            {promotionInfo.orderPromotion?.promotionName?.map(
                                              (name) => {
                                                return (
                                                  <DiscountName>
                                                    {name}
                                                  </DiscountName>
                                                );
                                              }
                                            )}
                                          </DiscountNameBlock>
                                        </DiscountWrapper>
                                      )}
                                      {promotionInfo.shippingFeePromotion && (
                                        <DiscountWrapper>
                                          <Discount>
                                            <DiscountDescription>
                                              {
                                                promotionInfo
                                                  .shippingFeePromotion
                                                  ?.blockDoc
                                              }
                                            </DiscountDescription>
                                            <DiscountPrice>{`$${promotionInfo.shippingFeePromotion?.discountAmount}`}</DiscountPrice>
                                          </Discount>
                                          <DiscountNameBlock>
                                            {promotionInfo.shippingFeePromotion?.promotionName?.map(
                                              (name) => {
                                                return (
                                                  <DiscountName>
                                                    {name}
                                                  </DiscountName>
                                                );
                                              }
                                            )}
                                          </DiscountNameBlock>
                                        </DiscountWrapper>
                                      )}
                                    </DiscountList>
                                  </Tooltip>
                                )}
                              </DiscountNoticeWrapper>
                            </CardContentRowLabel>
                            <CardContentRowValue>
                              -${orderDetail.promotion_discount_amount}
                            </CardContentRowValue>
                          </CardContentRow>
                        )}
                        {orderDetail.clpe_deduct_amount !== 0 && (
                          <CardContentRow>
                            <CardContentRowLabel>紅利折抵</CardContentRowLabel>
                            <CardContentRowValue>
                              -${orderDetail.clpe_deduct_amount}
                            </CardContentRowValue>
                          </CardContentRow>
                        )}
                        {orderDetail.overpay_amount > 0 && (
                          <CardContentRow>
                            <CardContentRowLabel>
                              秤重商品溢收退款
                            </CardContentRowLabel>
                            <CardContentRowValue>
                              -${orderDetail.overpay_amount}
                            </CardContentRowValue>
                          </CardContentRow>
                        )}
                        <CardContentRow className="total">
                          <CardContentRowLabel bold>總計</CardContentRowLabel>
                          <CardContentRowValue>
                            ${orderDetail.total_amount}
                          </CardContentRowValue>
                        </CardContentRow>
                      </PriceBlock>
                    </>
                  ) : (
                    <Skeleton className="skeleton-product-list" />
                  )}
                </CardContentTemplate1>
              )}
              {[2].includes(cardContentTemplate) && (
                <CardContentTemplate2>
                  <OrderInfoBlock>
                    <OrderInfoBlockLabel>收件資料</OrderInfoBlockLabel>
                    <OrderInfoBlockValue>
                      <div>{`${orderDetail.recipient} ${orderDetail.customer_phone}`}</div>
                      <div>{`${orderDetail.zipcode} ${orderDetail.shipping_address}`}</div>
                      <div>{`${orderDetail.elevator_type_name} ${orderDetail.receive_type_name}`}</div>
                    </OrderInfoBlockValue>
                  </OrderInfoBlock>
                  <OrderInfoBlock>
                    <OrderInfoBlockLabel>配送方式</OrderInfoBlockLabel>
                    <OrderInfoBlockValue>
                      <div>物流方式：{orderDetail.shipping_model_name}</div>
                      <div>出貨門店：{orderDetail.from_store_name}</div>
                      <div>
                        配送時間：
                        {`${orderDetail.order_date} ${orderDetail.expected_shipping_time}`}
                      </div>
                    </OrderInfoBlockValue>
                  </OrderInfoBlock>
                  <OrderInfoBlock>
                    <OrderInfoBlockLabel>付款方式</OrderInfoBlockLabel>
                    <OrderInfoBlockValue>
                      <div>{`${orderDetail.ecorder_payment} ${orderDetail.payment_status}`}</div>
                      <div>
                        {`付款時間：${orderDetail.real_payment_date}
                      ${orderDetail.real_payment_time}`}
                      </div>
                      <div>卡號：{orderDetail.credit_card}</div>
                    </OrderInfoBlockValue>
                  </OrderInfoBlock>
                  <OrderInfoBlock>
                    <OrderInfoBlockLabel>發票資訊</OrderInfoBlockLabel>
                    <OrderInfoBlockValue>
                      <div>
                        發票號碼：
                        {orderDetail.invoice_no !== ''
                          ? orderDetail.invoice_no
                          : orderDetail.invoice_status}
                      </div>
                      <div>發票隨機碼：{orderDetail.invoice_random_no}</div>
                      <div>發票方式：{orderDetail.invoice_type}</div>
                      <div>統一編號：{orderDetail.uniform_numbers}</div>
                      <div>
                        發票日期：
                        {`${orderDetail.invoice_apply_date} ${orderDetail.invoice_apply_time}`}
                      </div>
                      <div>發票金額：{orderDetail.invoice_no_amount}</div>
                    </OrderInfoBlockValue>
                  </OrderInfoBlock>
                  <OrderInfoBlock>
                    <OrderInfoBlockLabel>備註</OrderInfoBlockLabel>
                    <OrderInfoBlockValue>
                      {orderDetail.shipping_address_note || '無'}
                    </OrderInfoBlockValue>
                  </OrderInfoBlock>
                </CardContentTemplate2>
              )}
              {showAssistance && (
                <AssistanceWrapper>
                  <Assistance
                    orderDetail={orderDetail}
                    onClose={closeAssistance}
                    getOrderDetail={getOrderDetail}
                  />
                </AssistanceWrapper>
              )}
            </Card>
          </Container>

          <ModalDiscount
            open={showDiscountModal}
            fromDetail={true}
            promotionInfo={promotionInfo}
            onClose={() => setShowDiscountModal(false)}
          />
        </Layout>
      )}
    </>
  );
};

export default OrderDetail;
